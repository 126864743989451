.vf-icon-idea-details {
    &:before{
        content: url('../../../assets/icons/VF_light_bulb_rgb.svg')
    }
}

.vf-icon-expert-view {
    &:before{
        content: url('../../../assets/icons/VF_lecture_rgb.svg')
    }
}

.vf-icon-one-pager {
    &:before{
        content: url('../../../assets/icons/VF_knowledge_rgb.svg')
    }
}
