@import "~@vf-alchemy/vattenfall-design-system/scss/default-import";

.contact-wrapper {
  margin: 0 20px;

  @include media-breakpoint-up(md) {
    margin: 0;
  }
  @include media-breakpoint-up(lg) {
    min-height: 300px;
  }
}

.contact-fields {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .image-upload-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    width: 100%;

    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }

    .contact-details__image-wrapper {
      margin-top: 35px;
    }
  }

  .image-upload {
    max-width: 350px;
    margin-right: 20px;
  }
}

.contact-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  @include media-breakpoint-up(lg) {
    flex-direction: row;
    flex-wrap: wrap;
    height: 150px;
    align-items: center;
    justify-content: flex-start;
  }

  &__name, &__job, &__email, &__group, &__description {
    width: 100%;
    margin-bottom: 5px;

    .vf-dropdown, .vf-dropdown-label {
      min-height: 58px;
    }

    @include media-breakpoint-up(lg) {
      width: 400px;
      margin: 0 10px 5px 10px;
      height: 58px;
    }
  }

  &__remove {
    cursor: pointer;
    margin: 0 5px 5px 5px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }
  }

  &__submit {
    width: 220px;
    max-width: 220px;
    margin-bottom: 10px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }
  }
}

.contact-people {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(215px, 1fr));
  grid-gap: 20px;
  justify-content: center;
  padding: 15px;

  .contact-person {
    display: flex;
    flex-direction: column;
    align-items: center;

    .contact-details {
      &__name {
        margin-top: 5px;
        font-size: 1.2rem;
      }

      &__job {
        font-size: 0.8rem;
      }

      &__email {
        font-size: 0.8rem;
        margin-top: 5px;
        text-align: center;
        width: 100%;
        white-space: break-spaces;
        line-height: 1.2;
      }
    }
  }
}

.contact-details__image-wrapper {
  border-radius: 50%;
  overflow: hidden;
  width: 150px;
  height: 150px;
  border: 1px solid lightgrey;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &.no-image {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(to bottom right, transparent 49.5%, lightgrey 49.5%, lightgrey 50.5%, transparent 50.5%);
    }
  }
}

.contact-group {
  margin-top: 40px;

  .contact-group-title, .contact-group-description {
    text-align: center;
    margin-bottom: 10px;
  }
}
