@import "~@vf-alchemy/vattenfall-design-system/scss/default-import";

.overview-page {
  .hero {
    height: 30vh;

    &.no-video {
      width: 100%;
      min-height: 300px !important;
      @include media-breakpoint-up(md) {
        height: 300px;
      }
    }

    &__logo {
      position: absolute;
      left: 25px;
      height: 40px;

      @include media-breakpoint-up(xl) {
        left: 30px;
        height: 60px;
      }
    }
  }
}

.hero {
  width: 100%;
  position: relative;
  min-height: 30vh;

  .vf-hero__content{
    display: flex;
    flex-flow: row;
    justify-content: center;
  }

  &.no-video {
    width: 100%;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.15);
      z-index: 1;
      display: block;
    }

    .hero__logo, .vf-hero__content {
      z-index: 2;
    }

    @include media-breakpoint-up(md) {
      margin-bottom: 2rem;
      height: 300px;
      min-height: unset;
    }
  }

  &.video-embedded {
    @include media-breakpoint-up(md) {
     background: none !important;
    }
    &::before {
      display: none;
    }
  }

  &__video-embedded-wrapper {
    display: none;
    z-index: 2;

    @include media-breakpoint-up(md) {
      display: block;
    }

    @include media-breakpoint-up(lg) {
      min-height: 300px;
    }
  }

  &__video-container {
    width: 100%;
    height: 100%;
    border: 1px solid #fff;

    video {
      width: 100%;
      height: 100%;
      min-height: 300px;
      object-fit: cover;
      display: none;

      &:focus {
        border: none;
        outline: none;
      }

      @include media-breakpoint-up(lg) {
        display: block;
      }
    }
  }

  &__logo {
    position: absolute;
    right: 25px;
    top: 25px;
    height: 40px;

    @include media-breakpoint-up(lg) {
      height: 60px;
    }

    @include media-breakpoint-up(xl) {
      right: 50px;
      height: 80px;
    }
  }

  &__title {
    font-size: 2.5rem;
    margin: 0 60px;

    @include media-breakpoint-up(lg) {
      font-size: 3.6rem;
      margin: 0 80px;
    }

    @include media-breakpoint-up(xl) {
      max-width: 1000px;
      margin: 0 120px;
      font-size: 4rem;
    }
  }
}
