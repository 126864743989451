@import "~@vf-alchemy/vattenfall-design-system/scss/default-import";


.timeline-container {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1000;
  padding: 0;
  display: flex;
  justify-content: center;
  height: 80px;
  background: white;
  transition: box-shadow 0.3s ease;
  overflow: hidden;

  &.is-sticky {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  }
}


.timeline {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 auto;
  overflow-x: auto;
  white-space: nowrap;
  width: 100%;
  overflow-y: hidden;
  z-index: 3;
  padding: 0 20px 0 100px;

  /* Hide scrollbar for Chrome, Safari, and Opera */
  ::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge, and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.timeline::after {
  content: '';
  position: absolute;
  height: 2px;
  background: linear-gradient(to right, #cce4ff, #006edc);
  width: calc(100% + 100px);
  left: -100px;
  z-index: 2;
  border-radius: 5px;
}

.year-box, .star-box {
  position: relative;
  display: inline-block;
  margin: 0 10px;
  padding: 10px;
  background-color: white;
  border-radius: 8px;
  transition: transform 0.3s ease, background-color 0.3s ease;
  cursor: pointer;
  font-size: 18px;
  z-index: 3;
  border: 4px solid white;
  flex-shrink: 0;
}

.star-box {
  font-size: 16px;
  cursor: default;

  .star-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;

    img {
      padding-left: 5px;
      width: 20px;
      height: auto;
    }
  }
}

.year-box.current {
  background-color: #1964a3;
  color: white;
  transform: scale(1.1);
}

.year-box.current:hover {
  transform: scale(1.1);
  background-color: #1964a3;
}

.year-box:hover {
  transform: scale(1.1);
  background-color: #f0f0f0;
}

.year-label {
  text-align: center;
  min-width: 50px;
}

.event-popover {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 300px;
  z-index: 2000;
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  max-height: 300px;
}

.event-item {
  margin-bottom: 10px;
  text-align: left;
}

.event-item h4 {
  margin: 0 0 5px 0;
  font-size: 16px;
  cursor: pointer;
  color: #007bff;
}

.event-item p {
  margin: 0;
  font-size: 14px;
}

.event-item a {
  color: #007bff;
  text-decoration: none;
}

.event-item a:hover {
  text-decoration: underline;
}

.arrow-icon, .arrow-icon__left {
  align-items: center;
  justify-content: center;
  display: none;

  @include media-breakpoint-up(lg) {
    display: flex;
  }

  img {
    width: 20px;
    height: auto;
  }
}

.arrow-icon__right {
  margin-right: 20px;
}

.arrow-icon__left {
  transform: rotate(180deg);
  margin-left: 20px;
}

.ongoing-ideas-box {
  position: relative;
  display: inline-block;
  margin: 0 10px;
  padding: 10px;
  border-radius: 8px;
  transition: transform 0.3s ease, background-color 0.3s ease;
  cursor: pointer;
  font-size: 18px;
  z-index: 3;
  flex-shrink: 0;

  &:hover {
    color: white;
    transform: scale(1.1);
    background-color: #1964a3;
  }
}

.event-image {
  max-width: 100%;
  height: auto;
  margin-top: 10px;
}
