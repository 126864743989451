@import '~@vf-alchemy/vattenfall-design-system/scss/default-import';

.nav-switch {
  position: absolute;
  top: 0.5em;
  left: 1.5em;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 14.5px 10px;
  height: 45px;

  &:focus {
    outline: none;
  }

  &__bar {
    display: block;
    width: 25px;
    height: 2px;
    background: $vattenfall-black;
    opacity: 0.8;
    border-radius: 9px;
    transition: 0.25s ease-in-out;
  }

  &__bar + &__bar {
    margin-top: 5px;
  }

  &--open {
    .nav-switch__bar {
      &:nth-child(1) {
        position: absolute;
        top: 22px;
        transform: rotate(45deg);
      }

      &:nth-child(2) {
        opacity: 0;
      }

      &:nth-child(3) {
        position: absolute;
        bottom: 21px;
        transform: rotate(-45deg);
      }
    }

    ~ .vf-navbar__nav {
      background: $white;
      position: absolute;
      top: 100%;
      height: auto;
      display: block;
      z-index: 10;
      box-shadow: $box-shadow;

      & > .vf-navbar__nav-item {
        text-align: left;

        &:not(:last-child) {
          border-bottom: 1px solid $gray-300;
        }
      }

      & > .vf-navbar__nav-item--active.has-children {
        &::after {
          display: none;
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .nav-switch {
    display: none;

    &--open {
      ~ .vf-navbar__nav {
        display: flex;
        position: static;
        box-shadow: none;

        & > .vf-navbar__nav-item {
          text-align: center;

          &:not(:last-child) {
            border: none;
          }
        }
      }
    }
  }
}
