@import "~@vf-alchemy/vattenfall-design-system/scss/default-import";


nav button {
  border: none;
  background: none;
  cursor: pointer;
}


.vf-navbar__nav-item--active {
  font-weight: 900 !important;

  &:after {
    display: none;
  }

  /*fix for items without children*/
  &.has-children {
    position: relative;

    &:after {
      content: " ";
      position: absolute;
      bottom: -10px;
      left: 50%;

      display: block;

      width: 0;
      height: 0;

      border: solid transparent;
      pointer-events: none;
      border-bottom-color: $vattenfall-blue-grey;
      border-width: 20px;
      margin-left: -20px;
    }
  }
}

.vf-navbar__nav-item.with-badge {
  display: flex;
  align-items: center;
  padding-right: 10px;
}

.vf-badge {
  pointer-events: none;

  @include media-breakpoint-up(lg) {
    pointer-events: initial;
  }
}

.main-header__logo {
  margin: 0 auto;
  width: 180px;
}

.vf-navbar__nav {
  padding: 0 20px;
  margin: 0;
  max-width: 100%;
}

.vf-navbar__subnav {
  width: 100%;
  display: block;
  margin: 0 20px;

  & > .vf-navbar__nav {
    width: 100%;
    margin: auto;
  }
}

.vf-navbar__nav-item .nav-icon__text {
  display: block;
  margin-right: 10px;
}

@include media-breakpoint-up(md) {
  .vf-navbar__nav {
    .vf-navbar__nav-item {
      font-size: 1rem;
      padding: 0 12px;
    }
  }
}

@include media-breakpoint-up(lg) {
  .vf-navbar__nav {
    max-width: 100%;

    .vf-navbar__nav-item {
      font-size: 1rem;
      padding: 0 15px;
    }
  }

  .vf-navbar__nav-item .nav-icon__text {
    display: none;
  }
}

@include media-breakpoint-up(xl) {
  .main-header__logo {
    width: 250px;
  }

  .vf-navbar__nav {
    padding-left: 0;
    max-width: calc(100% - 250px);
    justify-content: flex-end;

    .vf-navbar__nav-item {
      font-size: 1rem;
      padding: 0 10px;
    }
  }
}


@media (min-width: 1450px) {
  .vf-navbar__nav {

    .vf-navbar__nav-item {
      font-size: 1.25rem;
      padding: 0 15px;
    }
  }
}
