.ck.ck-character-grid__tiles {
  white-space: normal;
  min-width: 600px;
  overflow: auto;
  max-height: 400px;
  padding: var(--ck-spacing-small);

  > button.ck.ck-button {
    max-width: var(--ck-ui-component-min-height);
    padding: 0;
    align-items: center;
    justify-content: center;
  }
}
