@import "~@vf-alchemy/vattenfall-design-system/scss/default-import";

.search-form {
  @include media-breakpoint-down(sm) {
    display: none;

    &--fullScreen {
      display: block;
      z-index: 10;
      position: fixed;
      background: white;
      overflow: auto;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 20px;
    }

    &__buttons {
      button {
        width: 100% !important;
      }
    }

    &__top {
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
        font-size: 18px;
        font-weight: bold;
      }

      button {
        font-size: 30px;
        background: transparent;
        border: none;
        cursor: pointer;
        padding: 0;

        &:focus {
          outline: none;
        }
      }
    }
  }

  &__container {
    align-items: center;
  }

  &__field-group {
    div:not(:last-child) {
      margin-bottom: .5rem;

      @include media-breakpoint-up(md) {
        margin-bottom: 0;
      }
    }
  }


  &--md-hidden {
    @include media-breakpoint-up(md) {
      display: none;
    }
  }
}

#containsText {
  border: 1px solid rgb(232, 232, 233);
  padding: 12px 20px;

  &:hover {
    border: 1px solid rgb(105, 120, 140);
  }
}

.react-calendar {
  border-radius: 4px;
  border: 1px solid $border-color !important;

  &__tile {
    &--active {
      background: $vattenfall-mid-blue !important;

    }
  }
}

.react-daterange-picker {
  width: 100%;
  height: 50px;

  &__wrapper {
    width: 100%;
    height: 50px;
    padding: 12px 8px 12px 20px;
    border-radius: 4px;
    border: 1px solid rgb(232, 232, 233) !important;

    &:hover {
      border: 1px solid rgb(105, 120, 140) !important;
    }

    .react-daterange-picker__range-divider {
      flex-grow: 1;
    }
  }
}

.react-date-picker__inputGroup__divider {
  margin: 0 1px !important;
}

.react-daterange-picker {
  &__button {

    &:enabled:focus,
    &:enabled:hover {
      outline: none;

      .react-daterange-picker__button__icon {
        stroke: black !important;
      }
    }
  }

  &__inputGroup__input:invalid {
    background: #fff !important;
  }
}