.ideas-list {
  &__title {
    align-items: flex-end;
  }

  &__item {
    position: relative;
    border-left: 6px solid #eee;

    &__top-info {
      font-size: 14px;
      text-transform: uppercase;
    }

    &__description {
      font-size: 18px;
    }
  }
}

.idea-opinions {
  span {
    font-size: 14px;

    &:not(:last-child) {
      margin-right: 12px;
    }
  }
}

.idea-card__author {
  font-size: 14px;
}

.switch-layout {
  &__container {
    display: flex;
    justify-content: flex-end;
  }

  &__button {
    display: flex;
    justify-content: center;
    background: #eee;
    border: none;
    height: 40px;
    width: 40px;
    cursor: pointer;

    &:not(:last-child) {
      margin-right: 5px;
    }

    &:focus {
      outline: none;
    }

    &--active {
      background: #ccc;
    }

    img {
      width: 24px;
    }
  }
}