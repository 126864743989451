@import "~@vf-alchemy/vattenfall-design-system/scss/default-import";

.expert-view {
  &__table__container {
    overflow-x: auto;

    table {
      font-size: .8rem;
    }

    tbody td {
      text-align: center;
      vertical-align: middle;
    }

    tbody tr:last-child {
      border-top: 2px solid $dark;
    }
  }

  &__form-container {
    background: #eee;
    border-radius: 6px;
    padding: 20px;
  }
}