@import '~@vf-alchemy/vattenfall-design-system/scss/default-import';

.card-header {
  &__container {
    height: 160px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__category {
    text-transform: uppercase;
    font-size: 14px;
    padding: 2px 6px;
  }

  &__title {
    color: #333;
  }
}

.card-content {
  &__caption {
    font-size: 14px;
  }
}

.vf-card {
  img {
    aspect-ratio: 357/164;
    object-fit: cover;
  }

  .embedded-video-wrapper{
    height: 160px;
    display: flex;
    align-content: center;
    justify-content: center;
    background: lightgray;
  }

  &__title {
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 1rem;
    line-height: 22px;
    text-align: center;
  }

  &__subtitle {
    margin-bottom: 0;
    text-align: center;
    min-height: 37px;
  }

  &__text-accented {
    color: #1964a3;
    font-weight: 500;
  }

  &__bodytext {
    @include media-breakpoint-up(md) {
      height: 100px;
    }
  }
}

.vf-card.disabled {
  pointer-events: none;
}

.vf-card__text.with-markdown {
  & > * {
    font-size: inherit;
    line-height: inherit;
  }
}
