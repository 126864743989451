.load-more-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.news-item {
  margin-top: 8px;
  margin-bottom: 20px;

  &__metadata {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    font-size: 12px;
    border-top: 1px solid #ddd;
    border-bottom: 4px solid #edf1f6;
    margin-top: 8px;
    margin-bottom: 3px;

    .item-category {
      color: #2071b5;
      cursor: pointer;
    }
  }

  &__content {
    cursor: pointer;
    .item-heading {
      font-size: 21px;
    }
    .item-subheading {
      font-size: 14px;
    }
  }
}
