.modal {
  &__background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .8);
    z-index: 8;
  }

  &__container {
    z-index: 10;
    position: fixed;
    background: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    max-width: 100%;
    height: auto;
    overflow: auto;

    &--full-screen {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transform: none;
    }
  }

  &__close-button {
    font-size: 30px;
    text-align: right;

    button {
      background: transparent;
      border: none;
      cursor: pointer;

      &:focus {
        outline: none;
      }
    }
  }
}