@import "~@vf-alchemy/vattenfall-design-system/scss/default-import";

header {
  width: 100%;
  max-width: $bleed-container-max-width + 20px; // To add the borders; @alchemy solution from file node_modules@vf-alchemy\vattenfall-design-system\scss\components_hero.scss
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  align-content: center;
  position: relative;

  &>a {
    width: 250px;
  }
}