@import "~@vf-alchemy/vattenfall-design-system/scss/default-import";

.events-header {
  text-align: center;

  .next-event {
    display: inline-block;
    background-color: rgba(25, 100, 163, 0.1);
    padding: 10px 20px;
    border-radius: 30px;
    margin-top: 30px;

    strong {
      font-weight: bold;
      color: #1964a3;
    }
  }
}

.event-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: column;

  &__type {
    width: 100%;
    margin-bottom: 5px;
  }

  &__description {
    width: 100%;
    margin-bottom: 5px;
  }

  &__remove {
    cursor: pointer;
  }

  &__date {
    width: 100%;
    margin-bottom: 5px;
  }

  &__submit {
    width: 220px;
    max-width: 220px;
  }


  @include media-breakpoint-up(lg) {
    flex-flow: row;
    height: 100px;


    &__type {
      width: 200px;
      margin-bottom: 0;
    }

    &__description {
      width: 300px;
      margin-bottom: 0;
    }

    &__date {
      width: 300px;
      margin-bottom: 0;
    }
  }
}

.events-section {
  margin: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-flow: column;

  @include media-breakpoint-up(lg) {
    flex-flow: row;
    flex-wrap: wrap;
  }

  .event {
    margin: 5px 0;
    padding: 0 5px;
    display: flex;
    align-items: center;

    @include media-breakpoint-up(lg) {
      flex: 0 0 50%;
      padding: 0 10px 0 0;
    }

    .event-name {
      font-size: 0.8rem;
      line-height: 1.2;

      @include media-breakpoint-up(xl) {
        font-size: 1rem;
      }

      @include media-breakpoint-up(lg) {
        font-size: 0.8rem;
      }

      @include media-breakpoint-up(md) {
        font-size: 0.8rem;
      }

      @include media-breakpoint-up(sm) {
        font-size: 1rem;
      }
    }

    .event-type-icon {
      width: 24px;
      height: 24px;
    }

    .active-icon {
      width: 14px;
      height: 14px;
      margin-left: 5px;
      transition: color 0.3s;
      border: 1px solid #1964a3;
      border-radius: 50%;
      padding: 2px;

      &:hover {
        cursor: pointer;
      }
    }

    &__passed {
      margin: 5px 0;

      .event-type-icon, .event-name {
        filter: grayscale(1);
        opacity: .3;
      }
    }
  }
}

.react-datetime-picker {
  width: 100%;
  height: 50px;

  &__wrapper {
    width: 100%;
    height: 50px;
    padding: 12px 8px 12px 20px;
    border-radius: 4px;
    border: 1px solid rgb(232, 232, 233) !important;

    &:hover {
      border: 1px solid rgb(105, 120, 140) !important;
    }
  }
}

.react-datetime-picker__inputGroup__divider {
  margin: 0 1px !important;
}

.react-datetime-picker {
  &__button {
    border: none;
    background: none;

    &:enabled:focus,
    &:enabled:hover {
      outline: none;

      .react-datetime-picker__button__icon {
        stroke: black !important;
      }
    }
  }

  &__inputGroup__input {
    border: none;
  }

  &__inputGroup__input:invalid {
    background: #fff !important;
  }
}
