.with-custom-label {
  &--inline {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    align-content: stretch;

    & > * {
      margin: 0;
    }
  }
}
